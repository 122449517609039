/* eslint-disable import/prefer-default-export */
import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from 'src/reducers';
import { ENABLE_REDUX_LOGGER } from 'src/config';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'customer', 'purchaseOrder'],
  stateReconciler: autoMergeLevel2,
};

const loggerMiddleware = createLogger();

let rehydrationComplete;
const rehydrationPromise = new Promise((resolve) => {
  rehydrationComplete = resolve;
  return rehydrationComplete;
});
const rehydration = () => rehydrationPromise;

export function configureStore(preloadedState = {}) {
  const middlewares = [thunkMiddleware];

  if (ENABLE_REDUX_LOGGER) {
    middlewares.push(loggerMiddleware);
  }

  const middlewareEnhancer = composeWithDevTools(
    applyMiddleware(...middlewares)
  );

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = compose(...enhancers);

  const pReducer = persistReducer(persistConfig, rootReducer);

  const store = createStore(pReducer, preloadedState, composedEnhancers);
  const persistor = persistStore(store, null, () => rehydrationComplete());
  const persistorFlush = () => persistor.flush();

  return {
    store,
    persistor,
    persistorFlush,
  };
}

export {
  rehydration
};
