const crypto = require('crypto');

const removeAccents = (str) => {
  if (!str) return '';
  if (typeof str !== 'string') return '';
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const lowerCaseNoAccents = (str) => removeAccents(str).toLocaleLowerCase();

const generateLetterHash = (input) => {
  const hash = crypto.createHash('sha256').update(input).digest('hex');
  const letterHash = hash.replace(/[0-9]/g, '')?.toLowerCase() || 'a';
  return letterHash;
};

module.exports = {
  removeAccents,
  lowerCaseNoAccents,
  generateLetterHash,
};
