import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
            await axios.get('/logout');
          }
        }

        return Promise.reject(error);
      }
    );
  };

  async handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      await this.logout();
    }
  }

  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    axios.post('/login', { email, password })
      .then((response) => {
        if (response.data?.data?.user) {
          const store = response.data.data.user.Store;
          if (store.is_franchise) {
            if (!store.bsale_api_token_name) {
              const error = {
                errorMessage: 'Este local no tiene el token de Bsale configurado',
              };
              reject(error);
            } else if (!store.rdc_rut || !store.rdc_token) {
              const error = {
                errorMessage: 'Este local no tiene el rut o el token de Redelcom configurado',
              };
              reject(error);
            } else {
              this.setSession(response.data.data.accessToken, response.data.data.user.id);
              resolve(response.data.data.user);
            }
          } else {
            this.setSession(response.data.data.accessToken, response.data.data.user.id);
            resolve(response.data.data.user);
          }
        } else {
          reject(response.data.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginWithRutAndPassword = (rut, password) => new Promise((resolve, reject) => {
    axios.post('/login', { rut, password })
      .then((response) => {
        if (response.data?.data?.user) {
          const store = response.data.data.user.Store;
          if (store.is_franchise) {
            if (!store.bsale_api_token_name) {
              const error = {
                errorMessage: 'Este local no tiene el token de Bsale configurado',
              };
              reject(error);
            } else if (!store.rdc_rut || !store.rdc_token) {
              const error = {
                errorMessage: 'Este local no tiene el rut o el token de Redelcom configurado',
              };
              reject(error);
            } else {
              this.setSession(response.data.data.accessToken, response.data.data.user.id);
              resolve(response.data.data.user);
            }
          } else {
            this.setSession(response.data.data.accessToken, response.data.data.user.id);
            resolve(response.data.data.user);
          }
        } else {
          reject(response.data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve) => {
    axios.get('/admins/get-by-token')
      .then((response) => {
        if (response.data.data.admin) {
          resolve(response.data.data.admin);
        } else {
          resolve(null);
        }
      })
      .catch(() => {
        resolve(null);
      });
  })

  logout = async () => {
    this.setSession(null);
    await axios.get('/logout');
  }

  setSession = (accessToken, userId) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
    if (userId) {
      localStorage.setItem('userId', userId);
    }
  }

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
