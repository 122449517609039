/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  // ADD_CART,
  // SUBSTRACT_CART,
  // REMOVE_CART,
  SET_CUSTOMER,
  RESET_CUSTOMER,
  ADD_PRODUCT,
  UPDATE_CART_ITEM_AMOUNT,
  REMOVE_PRODUCT,
  RESET_CART,
  UPDATE_CART_PRODUCT,
  SET_CUSTOMER_TYPE,
  RESET_CUSTOMER_TYPE,
  SET_COUPON,
  RESET_COUPON,
  SET_COUPON_DISCOUNT,
  RESET_COUPON_DISCOUNT,
  // SET_GENERAL_DISCOUNT,
  RESET_GENERAL_DISCOUNT,
  SET_DOCUMENT_TYPE,
  RESET_DOCUMENT_TYPE,
  ADD_PAYMENT,
  UPDATE_PAYMENT,
  REMOVE_PAYMENT,
  RESET_PAYMENTS,
  SET_ORDER_STATUS,
  RESET_ORDER_STATUS,
  RESET_CUSTOMER_ORDER_ID,
  SET_CUSTOMER_ORDER_ID,
  SET_BULK,
  RESET_BULK,
  SET_APPLY_BULK,
  RESET_APPLY_BULK,
  SMS_SENT,
  SET_CUSTOMER_POINTS,
  SET_BILLING_INFO,
  RESET_BILLING_INFO,
  SET_ALL_DISCOUNTS,
  SET_CART_LOADING,
  RESET_ALL_DISCOUNTS,
  RESET_CART_LOADING,
  ADD_RECOMMENDED_PRODUCT,
  SET_RECOMMENDED_PRODUCTS_LOADED,
  SET_RECOMMENDED_RESPONSE_ID,
} from 'src/actions/cartActions';
import { DOCUMENT_TYPE_BOLETA, PAYMENT_TYPE_METHOD, STATUS_PENDIENTE } from 'src/utils/const';

import { v4 as uuidv4 } from 'uuid';

const paymentId = uuidv4();

const initialPayments = [{
  id: paymentId,
  payment_type: PAYMENT_TYPE_METHOD,
  amount: 0,
}];

const initialState = {
  user: null,
  type: null,
  cart: {},
  return_type: null,
  code: null,
  return_products: [],
  payment_types: [],
  document_type: DOCUMENT_TYPE_BOLETA,
  billing: {},
  return_id: null,
  order_id: null,
  order_state: STATUS_PENDIENTE,
  bulk: false,
  apply_bulk: false,
  subtotal: 0,
  coupon: null,
  coupon_discount: 0,
  general_discount: 0,
  total: 0,
  payments: initialPayments,
  sms_sent: false,
  points: 0,
  recommended_product: [],
  all_discounts: {},
  all_quantities: 0,
  cart_loading: false,
  order_status: STATUS_PENDIENTE,
  recommended_products_loaded: false,
  recommended_response_id: null,
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOMER: {
      const { customer } = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = customer;
      });
    }

    case RESET_CUSTOMER: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.cart = {};
        draft.order_id = null;
        draft.order_status = STATUS_PENDIENTE;
      });
    }

    case SET_CUSTOMER_TYPE: {
      const { type } = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.type = type;
      });
    }

    case RESET_CUSTOMER_TYPE: {
      return produce(state, (draft) => {
        draft.type = null;
      });
    }

    case ADD_PRODUCT: {
      const {
        product,
        amount,
        prices = {},
        maxQuantities = {},
      } = action.payload;
      const productId = product.id;
      let newCart = {};
      return produce(state, (draft) => {
        // Ensure we clear current session
        // Check if product is already in the cart
        newCart = { ...state.cart };
        newCart[productId] = newCart[productId]
          ? {
            product,
            amount: newCart[productId].amount + amount,
            prices: newCart[productId].prices,
            max_quantities: newCart[productId].max_quantities,
            quantities: newCart[productId].quantities,
            discounts: newCart[productId].discounts,
            coupon_discount: newCart[productId].coupon_discount
              ? newCart[productId].coupon_discount : 0,
            general_discount: newCart[productId].general_discount
              ? newCart[productId].general_discount : 0,
          }
          : {
            product,
            amount,
            prices,
            max_quantities: maxQuantities,
            quantities: {},
            discounts: {},
            coupon_discount: 0,
            general_discount: 0,
          };

        const otherProductQuantities = Object.keys(newCart)
          .filter((key) => Number(key) !== productId)
          .reduce((a, p) => a + newCart[p].amount, 0);

        draft.all_quantities = otherProductQuantities + newCart[productId].amount;
        draft.cart = { ...newCart };
      });
    }

    case UPDATE_CART_ITEM_AMOUNT: {
      const {
        product,
        amount,
      } = action.payload;
      const productId = product.id;
      let newCart = {};
      return produce(state, (draft) => {
        newCart = { ...state.cart };
        newCart[productId] = newCart[productId]
          ? {
            product,
            amount,
            prices: newCart[productId].prices,
            max_quantities: newCart[productId].max_quantities,
            quantities: newCart[productId].quantities,
            discounts: newCart[productId].discounts,
            coupon_discount: newCart[productId].coupon_discount
              ? newCart[productId].coupon_discount : 0,
            general_discount: newCart[productId].general_discount
              ? newCart[productId].general_discount : 0,
          }
          : {
            product,
            amount,
            prices: {},
            max_quantities: {},
            quantities: {},
            discounts: {},
            coupon_discount: 0,
            general_discount: 0,
          };

        const otherProductQuantities = Object.keys(newCart)
          .filter((key) => Number(key) !== productId)
          .reduce((a, p) => a + newCart[p].amount, 0);

        draft.all_quantities = otherProductQuantities + amount;
        draft.cart = { ...newCart };
      });
    }

    case REMOVE_PRODUCT: {
      const { productId } = action.payload;
      let newCart = {};
      return produce(state, (draft) => {
        // Ensure we clear current session
        newCart = { ...state.cart };
        draft.all_quantities = state.all_quantities - newCart[productId].amount;
        delete newCart[productId];
        draft.cart = { ...newCart };

        // Check if the cart is empty
        if (!Object.keys(newCart).length) {
          draft.subtotal = 0;
          draft.coupon_discount = 0;
          draft.general_discount = 0;
          draft.total = 0;
          draft.all_discounts = {};
          draft.all_quantities = 0;
        }
      });
    }

    case RESET_CART: {
      return produce(state, (draft) => {
        draft.cart = {};
      });
    }

    case UPDATE_CART_PRODUCT: {
      return produce(state, (draft) => {
        const { item } = action.payload;
        const newCart = { ...state.cart };
        newCart[item.product.id] = item;
        draft.cart = newCart;
      });
    }

    case SET_COUPON: {
      const { coupon } = action.payload;
      return produce(state, (draft) => {
        draft.coupon = coupon;
      });
    }

    case RESET_COUPON: {
      return produce(state, (draft) => {
        draft.coupon = null;
      });
    }

    case SET_COUPON_DISCOUNT: {
      const { discount } = action.payload;
      return produce(state, (draft) => {
        draft.coupon_discount = discount;
      });
    }

    case RESET_COUPON_DISCOUNT: {
      return produce(state, (draft) => {
        draft.coupon_discount = 0;
      });
    }

    // case SET_GENERAL_DISCOUNT: {
    //   const { discount } = action.payload;
    //   return produce(state, (draft) => {
    //     draft.general_discount = discount;
    //   });
    // }

    case RESET_GENERAL_DISCOUNT: {
      return produce(state, (draft) => {
        draft.general_discount = 0;
      });
    }

    case SET_DOCUMENT_TYPE: {
      const { document } = action.payload;
      return produce(state, (draft) => {
        draft.document_type = document;
      });
    }

    case RESET_DOCUMENT_TYPE: {
      return produce(state, (draft) => {
        draft.document_type = DOCUMENT_TYPE_BOLETA;
      });
    }

    case ADD_PAYMENT: {
      const { payment } = action.payload;
      return produce(state, (draft) => {
        const payments = [...draft.payments];
        payments.push({
          id: payment.id,
          payment_type: payment.payment_type,
          amount: payment.amount,
        });
        draft.payments = payments;
      });
    }

    case UPDATE_PAYMENT: {
      const { payment } = action.payload;
      return produce(state, (draft) => {
        const payments = [];

        state.payments.forEach((p) => {
          if (p.id === payment.id) {
            payments.push({
              id: p.id,
              payment_type: payment.payment_type,
              amount: payment.amount,
            });
          } else {
            payments.push(p);
          }
        });

        draft.payments = payments;
      });
    }

    case REMOVE_PAYMENT: {
      const { payment } = action.payload;
      return produce(state, (draft) => {
        const payments = [];
        draft.payments.forEach((p) => {
          if (p.id !== payment.id) {
            payments.push(p);
          }
        });

        draft.payments = payments;
      });
    }

    case RESET_PAYMENTS: {
      return produce(state, (draft) => {
        draft.payments = initialPayments;
      });
    }

    case SET_ORDER_STATUS: {
      const { status } = action.payload;
      return produce(state, (draft) => {
        draft.order_status = status;
      });
    }

    case RESET_ORDER_STATUS: {
      return produce(state, (draft) => {
        draft.order_status = STATUS_PENDIENTE;
      });
    }

    case RESET_CUSTOMER_ORDER_ID: {
      return produce(state, (draft) => {
        draft.order_id = null;
      });
    }

    case SET_CUSTOMER_ORDER_ID: {
      const { orderId } = action.payload;
      return produce(state, (draft) => {
        draft.order_id = orderId;
      });
    }

    case SET_BULK: {
      const { bulk } = action.payload;
      return produce(state, (draft) => {
        draft.bulk = bulk;
      });
    }

    case RESET_BULK: {
      return produce(state, (draft) => {
        draft.bulk = false;
      });
    }

    case SET_APPLY_BULK: {
      const { bulk } = action.payload;
      return produce(state, (draft) => {
        draft.apply_bulk = bulk;
      });
    }

    case RESET_APPLY_BULK: {
      return produce(state, (draft) => {
        draft.apply_bulk = false;
      });
    }

    case SMS_SENT: {
      return produce(state, (draft) => {
        draft.sms_sent = action.payload;
      });
    }

    case SET_CUSTOMER_POINTS: {
      return produce(state, (draft) => {
        draft.points = action.payload;
      });
    }

    case SET_BILLING_INFO: {
      const { billing } = action.payload;
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.billing = billing;
      });
    }

    case RESET_BILLING_INFO: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.billing = {};
      });
    }

    case SET_ALL_DISCOUNTS: {
      return produce(state, (draft) => {
        draft.all_discounts = action.payload;
      });
    }

    case SET_CART_LOADING: {
      return produce(state, (draft) => {
        draft.cart_loading = action.payload;
      });
    }

    case RESET_ALL_DISCOUNTS: {
      return produce(state, (draft) => {
        draft.all_discounts = {};
        draft.all_quantities = 0;
      });
    }

    case RESET_CART_LOADING: {
      return produce(state, (draft) => {
        draft.cart_loading = false;
      });
    }

    case ADD_RECOMMENDED_PRODUCT: {
      return produce(state, (draft) => {
        draft.recommended_product = action.payload;
      });
    }

    case SET_RECOMMENDED_PRODUCTS_LOADED: {
      return produce(state, (draft) => {
        draft.recommended_products_loaded = action.payload;
      });
    }

    case SET_RECOMMENDED_RESPONSE_ID: {
      return produce(state, (draft) => {
        draft.recommended_response_id = action.payload;
      });
    }

    default: {
      return state;
    }
  }
};

export default cartReducer;
